$color-black: #000000;
///
/// @file jp_print.scss
///
/// \brief Print-only specific styles
///

/// global basics

// Compass imports
@import '../../../../all/themes/elc_base/scss/global/compass-sass-mixins/compass';
@import 'breakpoint'; // media queries

///
/// Global
/// Global Sass/Compass utilities, and variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do NOT directly result in rendered CSS
///
@import '../../bb_base/scss/global/media-queries';
@import '../../bb_base/scss/global/mixins';
@import 'overrides/overrides';

/// print specific partials load here
// dir for all jp bb_base/scss/print
@import '../../bb_base/scss/print/print';
